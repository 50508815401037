import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Link, injectIntl } from "gatsby-plugin-react-intl";
import Layout from "../components/layout/layout";
import PublisherCard from "../components/publishers/publisherCard";
import "./styling/publishers.css";

const Publishers = ({ location, data: { allPublishersYaml }, intl }) => {
  const { locale, formatMessage } = intl;
  const { pathname } = location;
  const { nodes: publishers } = allPublishersYaml;
  const [shuffledPublishers, setShuffledPublishers] = useState([]);

  useEffect(() => {
    setShuffledPublishers(publishers.sort(() => 0.5 - Math.random()));
  }, []);

  return (
    <Layout
      display="true"
      path={pathname}
      title={formatMessage({ id: "publishers.title" })}
    >
      <div class="publishers">
        {shuffledPublishers.map((publisher) => (
          <div className="publisherLink">
            <Link to={publisher.latestPagePath}>
              <PublisherCard publisher={publisher} locale={locale} />
            </Link>
          </div>
        ))}
      </div>
      <div className="fillContainer" />
    </Layout>
  );
};

export const query = graphql`
  query Publishers {
    allPublishersYaml {
      nodes {
        backgroundColor
        name
        picture
        latestPagePath: gatsbyPath(filePath: "/{PublishersYaml.slug}")
        languageCode
      }
    }
  }
`;

export default injectIntl(Publishers);
