import React from "react";
import "./publisherCard.css";
import { useIntl } from "gatsby-plugin-react-intl";

const PublisherCard = (props) => {
  const {
    publisher: { name, picture, languageCode, backgroundColor },
    locale,
  } = props;
  const intl = useIntl();
  const langLabel =
    intl.locale !== languageCode ? (locale === "de-DE" ? "EN" : "DE") : null;

  return (
    <div className="publisher">
      <img className="publisherPicture" src={picture} />
      <div
        style={{ background: "#" + backgroundColor }}
        className={`publisherNameWrapper ${
          langLabel ? "publisherNameWithLabel" : ""
        }`}
      >
        <span className="publisherName">{name}</span>
        {langLabel && <div className="langLabel">{langLabel}</div>}
      </div>
    </div>
  );
};

export default PublisherCard;
